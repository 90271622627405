import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll'
import { Link } from "gatsby"


import './JobDetails.scss';
const JobDetails = (props) => {
    return (
      <React.Fragment>
  
      </React.Fragment>
        
        
        )
};
export default JobDetails;