import React, { useState } from "react"
import { Button, Tabs, Tab, Form, Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll'
import officer01 from '.././../../images/jobdetails/img01.png';
import officer02 from '.././../../images/jobdetails/tab-img.png';
import officer03 from '.././../../images/jobdetails/desk-img.png';
import PlayVideo from "../../Play/PlayVideo";
import SocialShare from "@Components/Share/SocialShare";
import { useStaticQuery, graphql } from "gatsby";
import { JOBS_PAGE_URL, JOBSA_PAGE_URL, JOBSD_PAGE_URL } from "@Components/common/site/constants";
import './Officer.scss';
import getVideoId from 'get-video-id';
import YouTube from '@u-wave/react-youtube';
import { SaveItem } from "@Components/myaccount/wishlistlink"
import { numberWithCommas } from "@Components/common/site/common-functions"
const Officer = (props) => {
    const isNumber = (number) => Number.isFinite(number);
    const data = useStaticQuery(graphql`
    query{
        glstrapi {
            jobDetailSpeakWith(publicationState: LIVE) {
                Team {
                  Embed_Video_URL
                  Image {
                    alternativeText
                    url
                  }
                }
              }        
    }
}
    `);

    const [videoId, setVideoId] = useState('');
    const [showVideo, setShowVideo] = useState(false);

    const playVideo = () => {  
        var videoid = getVideoId(data.glstrapi?.jobDetailSpeakWith?.Team?.Embed_Video_URL);
        setVideoId(videoid.id)
        setShowVideo(true)
    }
    const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
      }

    const [isPlay, setPlay] = useState(false);

    // Capitalize
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    // Capitalize
    
    return (
        <React.Fragment>
            <section className="officer-info">
                <Container>
                    <Row>
                        <Col md={12} lg={8}>
                            <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                                <div className="about-officer">
                                    <h2>{props.data?.posting_title}</h2>
                                    {props.data?.city && props.data?.state &&
                                    <div className="officer-description">
                                            <i className="icon-location">
                                            </i>
                                        
                                        <span className="sm-content">
                                        {capitalize(props.data?.city.replace(/-/g," "))}{props.data?.city ? ', ' : ''} {props.data?.state}
                                          </span>
                                    </div>
                                    }
                                    {props.data?.salary &&
                                    <div className="officer-description">
                                            <i className="icon-money">
                                            </i>
                                        <span className="sm-content">
                                            {/* {Number.isInteger(parseInt(props.data?.salary)) ? 'true' : 'false'} */}
                                            {numberWithCommas(props.data?.salary)} {props.data?.salaryNAN ? "" : props.data?.salary ? <>{props.data?.currency}</> : null}
                                            </span>
                                    </div>
                                    }
                                    {props.data?.job_type &&
                                    <div className="officer-description">
                                            <i className="icon-clock">
                                            </i>
                                        <span className="sm-content">
                                            {capitalize(props.data?.job_type.replace(/-/g," "))}
                                          </span>
                                    </div>
                                    }
                                    {/* <div className="officer-description">
                                            <i className="icon-calendar">
                                            </i>
                                        <span className="sm-content expires">
                                            Expires in 3 days
                                          </span>
                                    </div> */}
                                    <div className="btn-groups d-none d-md-flex">
                                        <Link to={JOBSA_PAGE_URL.alias+'/'+props.id} className="btn btn-primary">
                                            apply for this job
                                                                                </Link>
                                                                        
                                        <SaveItem itemId={props.ObjectId} itemClass="like" />
                                        {/* <Link href="#" className="like">
                                            <i className="icon-like">

                                            </i>
                                            <span className="sm-text">
                                                shortlist
                                        </span>
                                        </Link> */}
                                        <SocialShare job_url={process.env.GATSBY_SITE_URL+JOBSD_PAGE_URL.alias+"/"+props.id} jobDetails={true} Title="Share" />
                                        {/* <Link href="#" className="like">
                                            <i className="icon-share">
                                            </i>
                                            <span className="sm-text">
                                                share
                                        </span>
                                        </Link> */}
                                    </div>
                                </div>
                            </ScrollAnimation>

                        </Col>
                        {data.glstrapi?.jobDetailSpeakWith?.Team &&
                        <Col md="12" lg={4}>
                            <ScrollAnimation animateOnce={true} animateIn='fadeInRight'>
<div className="vi-bit">
                            {/* {showVideo && <div onClick={(e) => {setShowVideo(false)}} className="close-iframe-btn"></div> }
                                <div className="officer-img img-zoom">
                                {showVideo &&
                        <YouTube
                        video={videoId}
                        autoplay
                        onEnd={e => {setShowVideo(false)}}
                        modestBranding={1}
                        onPlaying={trackerVideo('Job Detail Page Team Member')}
                        showRelatedVideos={false}
                        showInfo={false}
                        annotations={false}
                        
                        />
                        }
                                    <picture>
                                        <source media="(min-width:992px)" srcSet={data.glstrapi?.jobDetailSpeakWith?.Team?.Image?.url} alt={data.glstrapi?.jobDetailSpeakWith?.Team?.Image?.alternativeText} />
                                        <source media="(min-width:768px)" srcSet={data.glstrapi?.jobDetailSpeakWith?.Team?.Image?.url} alt={data.glstrapi?.jobDetailSpeakWith?.Team?.Image?.alternativeText} />
                                        <img src={data.glstrapi?.jobDetailSpeakWith?.Team?.Image?.url} alt={data.glstrapi?.jobDetailSpeakWith?.Team?.Image?.alternativeText} />
                                    </picture>
                                    {data.glstrapi?.jobDetailSpeakWith?.Team?.Embed_Video_URL && !showVideo &&
                                    <div className="video-buttons">
                                        <strong className="video-btn" onClick={e => {playVideo()}}>

                                            <i className="icon-video ">
                                            </i>
                                        </strong>
                                    </div>
                                    }
                                </div> */}
                                </div>
                            </ScrollAnimation>

                        </Col>
                        }
                    </Row>
                </Container>
            </section>
            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId="V8ksSGxo6no"
                isAutoPlay={1}
            />
        </React.Fragment>

    )
};
export default Officer;