import React, {useState, useEffect} from "react"
import { Button, Tabs, Tab, Form, Container, Row, Col, NavLink } from "react-bootstrap";
import { Link } from "gatsby"
import {isMobile, isMobileTab} from '../../jsutility/index'
import './SearchProfile.scss';
import SocialShare from "@Components/Share/SocialShare";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { JOBS_PAGE_URL, JOBSA_PAGE_URL, JOBSD_PAGE_URL, JOBSD } from "@Components/common/site/constants";
import { numberWithCommas } from "@Components/common/site/common-functions"
import { SaveItem } from "@Components/myaccount/wishlistlink"
const RELATED_JOBS =  gql`
query GetJobs($_id: String!, $title: String!){
    jobs (where:{id_ne:$_id, industry:$title, publish: true}, limit: 6) {
        id
        slug
        posting_title
        job_type
        salary
          currency
          city
          state
    }
}
`;

// Capitalize
const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
// Capitalize

const SearchProfile = (props) => {
    console.log(props, "RELATED_JOBSRELATED_JOBSRELATED_JOBS")
    let property_slug = props.id?.split('-');
    let id = property_slug ? property_slug[property_slug.length-1] : null;
    const { loading:relatedjobsloading, error:relatedjobserror, data:relatedjobs } = useQuery(RELATED_JOBS, {
        variables: { _id: id, title: props.title }
      });
    //   console.log(relatedjobs)
    // isMobile()|| isMobileTab() ? 3 : 6
        if (relatedjobsloading) return <p>Loading ...</p>;

        return (

        <React.Fragment>
            <section className={relatedjobs?.jobs?.length > 0 ? "search-profile" : "search-profile top"}>
                <Container>
                    <div className="search-row-blk">
                    {relatedjobs?.jobs?.length > 0 ?
                    <>
                <h2>Similar jobs</h2>
                    <Row>
                        {relatedjobs?.jobs?.slice(0, relatedjobs?.jobs?.length < 5 ? 3 : 6)?.map((item, index )=> (
                        <Col md={12} lg={4}  className="pl-xxl-0" >
                            <Link to={JOBSD_PAGE_URL.alias+'/'+item.slug+'-'+item.id}>
                            <div className="search-info">
                                <h3>{item.posting_title}</h3>
                                <div className="search-description">
                                    <Link href="#">
                                        <i className="icon-location">
                                        </i>
                                    </Link>

                                    <span className="sm-content">
                                    {capitalize(item?.city.replace(/-/g," "))}{item?.city ? ', ' : ''} {item?.state}
                                          </span>
                                </div>
                                <div className="search-description">
                                    <Link href="#">
                                        <i className="icon-clock">
                                        </i>
                                    </Link>

                                    <span className="sm-content">
                                        {capitalize(item.job_type.replace(/-/g," "))}
                                          </span>
                                </div>
                                {item.salary &&
                                <div className="search-description">
                                        <i className="icon-money">
                                        </i>
                                    
                                    <span className="sm-content">
                                        {numberWithCommas(item.salary)} {item.currency} 
                                          </span>
                                </div>
}

                            </div>
                            </Link>

                        </Col>
                        ))}

                    </Row>
                    </>
                    : ""}
                    <Row className="vito-fix">
                        <Col>
                        <div className="btn-groups d-md-none">
                                        <Link to={JOBSA_PAGE_URL.alias+'/'+props.id} className=" btn btn-primary">
                                        apply for this job 
                                        </Link>
                                        <SaveItem itemId={props.ObjectId} itemClass="icons" />
                                         {/* <Link href="#" className="icons">
                                             <i className="icon-like">

                                             </i>
                                        </Link> */}
                                        <SocialShare job_url={process.env.GATSBY_SITE_URL+JOBSD_PAGE_URL.alias+'/'+props.id} jobDetails={true} isMob={true} Title="Share" />

                                 </div>
                        </Col>
                    </Row>
                    </div>
                </Container>
            </section>
        </React.Fragment>
     )
};

export default SearchProfile;