import React from "react"
import { Button, Tabs, Tab, Form, Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll'
import './Slide.scss';
import { JOBS_PAGE_URL, JOBSA_PAGE_URL, JOBSD_PAGE_URL } from "@Components/common/site/constants";

const SlideBar = (props) => {
    return (
        <React.Fragment>

            <section className={props.path ? "ispath slide-menu" : "slide-menu"}>
                <Container>
                    <Row>

                        <Col lg={12}>
                            <div className="side-content">
                                {/* <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'> */}

                                <div className="slide-bar">
                                    {props.step === 7 ? null : props.videotrue ? null : props.step > 0 ?
                                        <a onClick={props.previous} className={"prev back"}>
                                            <i className="icon-prev"></i>

                                            <span className="sm-content">
                                                {!props.path ? 'back to search' : props.step === 0 ? 'Back to job details' : 'Back'}
                                            </span>
                                        </a> :
                                        <Link to={props.path1? '/job-wishlist' : !props.path ? JOBS_PAGE_URL.alias : props.step === 0 ? JOBSD_PAGE_URL.alias + '/' + props.backid : null} className={props.path ? "prev tim" : props.step === 0 ? "prev" : "prev back"}>
                                            <i className="icon-prev"></i>

                                            <span className="sm-content">
                                                {!props.path ? 'back to search' : props.step === 0 ? 'Back to job details' : 'Back'}
                                            </span>
                                        </Link>
                                    }

                                </div>
                                {/* </ScrollAnimation> */}
                                {!props.path ?
                                    <ScrollAnimation animateOnce={true} animateIn='fadeInRight'>
                                        {props.links?.map((link, i) => {
                                            return (
                                                link._id === props.id ?
                                                    <div className="slide-bar d-none d-md-block right-slide">
                                                        {i !== 0 ?
                                                            <Link to={JOBSD_PAGE_URL.alias + '/' + props.links[i - 1].slug + '-' + props.links[i - 1]._id} className="prev right-prev">
                                                                <i className="icon-prev right"></i>

                                                                <span className="sm-content prev-job">
                                                                    previous job &nbsp; {props.links?.length - 1 !== i ? '/' : null}
                                                                </span>
                                                            </Link>
                                                            : null}
                                                        {props.links?.length - 1 !== i ?
                                                            <Link to={JOBSD_PAGE_URL.alias + '/' + props.links[i + 1].slug + '-' + props.links[i + 1]._id} className="next more">

                                                                <span className="sm-content next-job">
                                                                    next job
                                                                </span>
                                                                <i className="icon-next "></i>
                                                            </Link>
                                                            : null}
                                                    </div>
                                                    : null)
                                        })}
                                    </ScrollAnimation>
                                    : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>

    )
};
export default SlideBar;